<template>
  <div class="page-content">
    <div style="background-color: #FFFFFF;padding: 20px;display: flex;justify-content: center;margin-top: 16px;">
      <el-input v-model="searchParams.keyword" clearable style="width: 500px;margin-right: 12px;" placeholder="关键词"></el-input>
      <el-button @click="loadData(1)" type="primary">检索</el-button>
    </div>
    <div v-if="data" style="background-color: #FFFFFF;padding: 20px 60px 40px 60px;margin-top: 16px;margin-bottom: 25px;">
      <div class="org-container">
        <el-row>
          <el-col :span="24">
            <div class="grid-container">
              <div class="grid-item" v-for="(item, index) in data.content" :key="index">
                <div class="grid-item-logo">
                  <div class="grid-item-top">
                    <div class="grid-item-icon"><img src="../assets/images/pdf-icon.png"></div>
                    <div class="grid-item-title">{{item.name}}</div>
                  </div>
                  <div class="grid-item-time">
                    <div>&nbsp;</div>
                    <div style="margin-left: 20px;">{{item.fileDate}}</div>
                    <div style="float: right;">{{item.pageSize}}</div>
                  </div>
                </div>
                <div class="grid-item-down">
                  <div class="grid-item-botton" @click="downLoad(item)">
                    <img src="../assets/images/down-icon.png">下载
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-pagination layout="prev, pager, next" background :page-size="20"
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>

import api from "../api";

export default {
  name: "SpecialReportList",
  data(){
    return {
      sourceData: {
        content: [],
        totalElements: 0,
        number: 1,
      },
      data: {
        content: [],
        totalElements: 0,
        number: 1,
      },
      searchParams:{
        keyword: null,
      },
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init() {
      await this.loadData(1)
    },
    async loadData(page){
      const params={
        ...this.searchParams,
        page:page-1,
        size:20,
      }
      this.data=(await api.listDocument(params)).data
    },
    downLoad(item){
      const loading = this.$loading({
        lock: true,
        text: '正在下载...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      try {
        loading.setText('正在下载...')
        let attachment=item.attachment
        api.downLoad(attachment).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', item.name+".pdf"); // 设置下载文件的名称
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link); // 清理并移除元素
          window.URL.revokeObjectURL(url); // 释放 URL 对象
          this.visible = false
        });
      } finally {
        loading.close()
      }
    }
  }
}
</script>

<style scoped>
.org-container {
  padding: 0px 50px 20px 50px;
  background: #ffffff;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 每行显示 5 列 */
  gap: 16px;
}

.grid-item-logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 180px;
  height: 240px;
  padding: 0px;
  border-radius: 0px;
  position: relative;
  background-image: url(../assets/images/zhuanti_bg.png);
  background-size: 100% 100%;
  /*display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;*/
}

/*.grid-item-logo:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}*/

.grid-item-top{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.logo {
  width: 80px;
  height: 80px;
  border-radius: 50%; /* 将图片变为圆形 */
  object-fit: cover; /* 确保图片内容适应圆形 */
}

.info {
  text-align: center;
  margin: 10px 0;
}
.grid-item-icon{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  width: 180px;
  margin: 8px 0px 0px 8px;
}
.grid-item-icon img{
  width: 28px;
}

.grid-item-title {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  margin: 15px 22px 0px 22px;
  color: #4D4D4D;
}

.grid-item-time {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin: 0px 0px 17px 0px;
  display: flex;
  width: 160px;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.icon-i {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000000;
  cursor: pointer;
  font-size: 18px;
}
.grid-item-down{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 180px;
  margin-top: 16px;
  margin-bottom: 0px;
}
.grid-item-botton{
  display: flex;
  width: 76px;
  height: 32px;
  gap: 0px;
  border-radius: 9px 9px 9px 9px;
  border: 1px solid #DEE8F3;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: center;
  text-decoration-skip-ink: none;
  color: #0356AD;
  cursor: pointer;
}
.grid-item-botton img{
  width: 24px;
}
</style>
